
/**
 * @type AbstractDagPlanningEntryRestModel
 * 
 * @export
 */
export type AbstractDagPlanningEntryRestModel = { _type: 'BEZOEK' } & DagPlanningBezoekEntryRestModel | { _type: 'HEADER' } & DagPlanningHeaderRestModel | { _type: 'PAUZE' } & DagPlanningPauzeRestModel | { _type: 'RIT' } & DagPlanningRitRestModel | { _type: 'STOP' } & DagPlanningStopRestModel | { _type: 'TEXT' } & DagPlanningTextRestModel;

/**
 * 
 * @export
 * @interface AccessoireLevering
 */
export interface AccessoireLevering {
    /**
     * 
     * @type {string}
     * @memberof AccessoireLevering
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLevering
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLevering
     */
    artikelNr: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLevering
     */
    artikelOmschrijving: string;
    /**
     * 
     * @type {number}
     * @memberof AccessoireLevering
     */
    gevraagdAantal: number;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLevering
     */
    creatieTijdstip: string;
    /**
     * 
     * @type {AccessoireLeveringOorsprong}
     * @memberof AccessoireLevering
     */
    oorsprong: AccessoireLeveringOorsprong;
}

/**
 * 
 * @export
 * @interface AccessoireLeveringOorsprong
 */
export interface AccessoireLeveringOorsprong {
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    type?: AccessoireLeveringOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    prestatieId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    verkoopOrderNr: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    verkoopOrderPlatformId: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    bestellingId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringOorsprong
     */
    bestellingNummer?: string;
}

/**
* @export
* @enum {string}
*/
export enum AccessoireLeveringOorsprongTypeEnum {
    PlatformPrestatie = 'PLATFORM_PRESTATIE',
    DynamicsOrderFromAptus = 'DYNAMICS_ORDER_FROM_APTUS'
}

/**
 * 
 * @export
 * @interface AccessoireLeveringUitgevoerd
 */
export interface AccessoireLeveringUitgevoerd {
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerd
     */
    _type?: string;
    /**
     * 
     * @type {Array<AccessoireLeveringUitgevoerdAccessoireLevering>}
     * @memberof AccessoireLeveringUitgevoerd
     */
    accessoireLeveringen: Array<AccessoireLeveringUitgevoerdAccessoireLevering>;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerd
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface AccessoireLeveringUitgevoerdAccessoireLevering
 */
export interface AccessoireLeveringUitgevoerdAccessoireLevering {
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerdAccessoireLevering
     */
    accessoireLeveringId: string;
    /**
     * 
     * @type {Array<AccessoireLeveringUitgevoerdGeleverdArtikel>}
     * @memberof AccessoireLeveringUitgevoerdAccessoireLevering
     */
    geleverdeArtikelen: Array<AccessoireLeveringUitgevoerdGeleverdArtikel>;
}

/**
 * 
 * @export
 * @interface AccessoireLeveringUitgevoerdGeleverdArtikel
 */
export interface AccessoireLeveringUitgevoerdGeleverdArtikel {
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerdGeleverdArtikel
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerdGeleverdArtikel
     */
    artikelNr: string;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerdGeleverdArtikel
     */
    artikelOmschrijving: string;
    /**
     * 
     * @type {number}
     * @memberof AccessoireLeveringUitgevoerdGeleverdArtikel
     */
    geleverdAantal: number;
    /**
     * 
     * @type {string}
     * @memberof AccessoireLeveringUitgevoerdGeleverdArtikel
     */
    onvolledigeLeveringReden?: AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum;
}

/**
* @export
* @enum {string}
*/
export enum AccessoireLeveringUitgevoerdGeleverdArtikelOnvolledigeLeveringRedenEnum {
    TeWeinigArtikelenBeschikbaar = 'TE_WEINIG_ARTIKELEN_BESCHIKBAAR',
    AnderSoortArtikelGeleverd = 'ANDER_SOORT_ARTIKEL_GELEVERD',
    KlantAkkoord = 'KLANT_AKKOORD',
    VraagKlant = 'VRAAG_KLANT'
}

/**
 * 
 * @export
 * @interface AdresBelemmerd
 */
export interface AdresBelemmerd {
    /**
     * 
     * @type {string}
     * @memberof AdresBelemmerd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof AdresBelemmerd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof AdresBelemmerd
     */
    reden?: AdresBelemmerdRedenEnum;
    /**
     * 
     * @type {string}
     * @memberof AdresBelemmerd
     */
    toelichting?: string;
}

/**
* @export
* @enum {string}
*/
export enum AdresBelemmerdRedenEnum {
    AdresNietGevonden = 'ADRES_NIET_GEVONDEN',
    AdresOnbereikbaar = 'ADRES_ONBEREIKBAAR',
    AdresUitzonderlijkGesloten = 'ADRES_UITZONDERLIJK_GESLOTEN',
    AdresGeslotenOpeningsurenIncorrect = 'ADRES_GESLOTEN_OPENINGSUREN_INCORRECT',
    AdresGeslotenOpeningsurenCorrectMaarBuitenOpeningsurenAangekomen = 'ADRES_GESLOTEN_OPENINGSUREN_CORRECT_MAAR_BUITEN_OPENINGSUREN_AANGEKOMEN',
    KlantVerhuisd = 'KLANT_VERHUISD',
    NietVoldoendeTijdDoorOpeningsuren = 'NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN',
    NietVoldoendeTijdDoorEindeShift = 'NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT',
    PlanningswijzigingVanuitAqualex = 'PLANNINGSWIJZIGING_VANUIT_AQUALEX',
    Andere = 'ANDERE'
}

/**
 * 
 * @export
 * @interface ArtikelRestModel
 */
export interface ArtikelRestModel {
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    nummer: string;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    type: ArtikelRestModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    bedrijfId: string;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    afbeeldingUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ArtikelRestModel
     */
    hervulbaar?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ArtikelRestModel
     */
    inhoud?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ArtikelRestModel
     */
    vervangbaar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArtikelRestModel
     */
    installeerbaar?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArtikelRestModel
     */
    onderdeelSoort?: string;
}

/**
* @export
* @enum {string}
*/
export enum ArtikelRestModelTypeEnum {
    Algemeen = 'ALGEMEEN',
    Filter = 'FILTER',
    Co2 = 'CO2'
}

/**
 * 
 * @export
 * @interface BezoekSessie
 */
export interface BezoekSessie {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessie
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessie
     */
    datum: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof BezoekSessie
     */
    actieveTechniciIds: Set<string>;
}

/**
 * 
 * @export
 * @interface BezoekSessieBundleRestModel
 */
export interface BezoekSessieBundleRestModel {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieBundleRestModel
     */
    bezoekSessieId: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieBundleRestModel
     */
    datum: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieBundleRestModel
     */
    serviceAdresId: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieBundleRestModel
     */
    bezoekId: string;
    /**
     * 
     * @type {DagPlanningBezoekRestModel}
     * @memberof BezoekSessieBundleRestModel
     */
    dagPlanningBezoek: DagPlanningBezoekRestModel;
    /**
     * 
     * @type {ServiceAdres}
     * @memberof BezoekSessieBundleRestModel
     */
    serviceAdres: ServiceAdres;
    /**
     * 
     * @type {Klant}
     * @memberof BezoekSessieBundleRestModel
     */
    klant: Klant;
    /**
     * 
     * @type {Array<Toestel>}
     * @memberof BezoekSessieBundleRestModel
     */
    toestellen: Array<Toestel>;
    /**
     * 
     * @type {Array<BezoekSessieEventMessage>}
     * @memberof BezoekSessieBundleRestModel
     */
    events: Array<BezoekSessieEventMessage>;
}

/**
 * 
 * @export
 * @interface BezoekSessieEventMessage
 */
export interface BezoekSessieEventMessage {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieEventMessage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieEventMessage
     */
    frontendId?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieEventMessage
     */
    bezoekSessieId: string;
    /**
     * 
     * @type {BezoekSessieEventPayload}
     * @memberof BezoekSessieEventMessage
     */
    payload: BezoekSessieEventPayload;
    /**
     * 
     * @type {EventMetadata}
     * @memberof BezoekSessieEventMessage
     */
    metadata: EventMetadata;
}

/**
 * @type BezoekSessieEventPayload
 * 
 * @export
 */
export type BezoekSessieEventPayload = { _type: 'ACCESSOIRE_LEVERING_UITGEVOERD' } & AccessoireLeveringUitgevoerd | { _type: 'ADRES_BELEMMERD' } & AdresBelemmerd | { _type: 'BEZOEK_SESSIE_GESLOTEN' } & BezoekSessieGesloten | { _type: 'BEZOEK_SESSIE_GESTART' } & BezoekSessieGestart | { _type: 'BEZOEK_SESSIE_SLUITEN_AANKONDIGING' } & BezoekSessieSluitenAankondiging | { _type: 'BEZOEK_SESSIE_UIT_TE_VOEREN_WERK_HERLADEN' } & BezoekSessieUitTeVoerenWerkHerladen | { _type: 'CHECK_IN_BIJ_TOESTEL' } & CheckInBijToestel | { _type: 'CHECK_IN_OP_ADRES' } & CheckInOpAdres | { _type: 'CHECK_OUT_VAN_ADRES' } & CheckOutVanAdres | { _type: 'CHECK_OUT_VAN_TOESTEL' } & CheckOutVanToestel | { _type: 'CO2_GELEVERD' } & Co2Geleverd | { _type: 'DEVICE_INFO_GELOGD' } & DeviceInfoGelogd | { _type: 'GPS_LOCATIE_GELOGD' } & GpsLocatieGelogd | { _type: 'HANDTEKENING_GEZET' } & HandtekeningGezet | { _type: 'INSTALLATIE_TIJD_INGEGEVEN' } & InstallatieTijdIngegeven | { _type: 'INSTALLATIE_UITGEVOERD' } & InstallatieUitgevoerd | { _type: 'INTERNE_FEEDBACK_TOEGEVOEGD' } & InterneFeedbackToegevoegd | { _type: 'INTERVENTIE_TIJD_INGEGEVEN' } & InterventieTijdIngegeven | { _type: 'ONDERWEG_NAAR_ADRES' } & OnderwegNaarAdres | { _type: 'TECHNICUS_TOEGEVOEGD' } & TechnicusToegevoegd | { _type: 'TECHNICUS_VERWIJDERD' } & TechnicusVerwijderd | { _type: 'TOESTEL_FOTO_TOEGEVOEGD' } & ToestelFotoToegevoegd | { _type: 'TOESTEL_FOTO_VERWIJDERD' } & ToestelFotoVerwijderd | { _type: 'TOESTEL_INFORMATIE_AANGEPAST' } & ToestelInformatieAangepast | { _type: 'TOESTEL_INTERVENTIE_UITGEVOERD' } & ToestelInterventieUitgevoerd | { _type: 'TOESTEL_ONDERHOUD_UITGEVOERD' } & ToestelOnderhoudUitgevoerd | { _type: 'TOESTEL_STUKLIJST_AANGEPAST' } & ToestelStuklijstAangepast | { _type: 'UIT_TE_VOEREN_WERK_ZONDER_BEZOEK_SESSIE' } & UitTeVoerenWerkZonderBezoekSessie | { _type: 'UI_FORM_DRAFT' } & UiFormDraft | { _type: 'WATERSTAND_OPGENOMEN' } & WaterstandOpgenomen;

/**
 * 
 * @export
 * @interface BezoekSessieGesloten
 */
export interface BezoekSessieGesloten {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGesloten
     */
    _type?: string;
}

/**
 * 
 * @export
 * @interface BezoekSessieGestart
 */
export interface BezoekSessieGestart {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGestart
     */
    datum: string;
    /**
     * 
     * @type {number}
     * @memberof BezoekSessieGestart
     */
    planningVersie: number;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGestart
     */
    serviceAdresId: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGestart
     */
    planningVersieTijdstip: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGestart
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieGestart
     */
    bezoekId: string;
    /**
     * 
     * @type {Set<string>}
     * @memberof BezoekSessieGestart
     */
    technicusIds: Set<string>;
    /**
     * 
     * @type {UitTeVoerenWerk}
     * @memberof BezoekSessieGestart
     */
    uitTeVoerenWerk: UitTeVoerenWerk;
}

/**
 * 
 * @export
 * @interface BezoekSessieInsertEventResponse
 */
export interface BezoekSessieInsertEventResponse {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieInsertEventResponse
     */
    reden?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieInsertEventResponse
     */
    bestaandeBezoekSessieId?: string;
}

/**
 * 
 * @export
 * @interface BezoekSessieSluitenAankondiging
 */
export interface BezoekSessieSluitenAankondiging {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieSluitenAankondiging
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieSluitenAankondiging
     */
    tijdstip?: string;
    /**
     * 
     * @type {number}
     * @memberof BezoekSessieSluitenAankondiging
     */
    periodeNaTijdstip?: number;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieSluitenAankondiging
     */
    periode: BezoekSessieSluitenAankondigingPeriodeEnum;
}

/**
* @export
* @enum {string}
*/
export enum BezoekSessieSluitenAankondigingPeriodeEnum {
    VastTijdstip = 'VAST_TIJDSTIP',
    PeriodeNaBepaaldTijdstip = 'PERIODE_NA_BEPAALD_TIJDSTIP'
}

/**
 * 
 * @export
 * @interface BezoekSessieUitTeVoerenWerkHerladen
 */
export interface BezoekSessieUitTeVoerenWerkHerladen {
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieUitTeVoerenWerkHerladen
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieUitTeVoerenWerkHerladen
     */
    technicusId?: string;
    /**
     * 
     * @type {string}
     * @memberof BezoekSessieUitTeVoerenWerkHerladen
     */
    versieTijdstip: string;
    /**
     * 
     * @type {UitTeVoerenWerk}
     * @memberof BezoekSessieUitTeVoerenWerkHerladen
     */
    uitTeVoerenWerk: UitTeVoerenWerk;
}

/**
 * 
 * @export
 * @interface CheckInBijToestel
 */
export interface CheckInBijToestel {
    /**
     * 
     * @type {string}
     * @memberof CheckInBijToestel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckInBijToestel
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckInBijToestel
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface CheckInOpAdres
 */
export interface CheckInOpAdres {
    /**
     * 
     * @type {string}
     * @memberof CheckInOpAdres
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckInOpAdres
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface CheckOutVanAdres
 */
export interface CheckOutVanAdres {
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanAdres
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanAdres
     */
    redenNietAfgewerkt?: CheckOutVanAdresRedenNietAfgewerktEnum;
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanAdres
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanAdres
     */
    toelichting?: string;
}

/**
* @export
* @enum {string}
*/
export enum CheckOutVanAdresRedenNietAfgewerktEnum {
    NietVoldoendeTijdDoorOpeningsuren = 'NIET_VOLDOENDE_TIJD_DOOR_OPENINGSUREN',
    NietVoldoendeTijdDoorEindeShift = 'NIET_VOLDOENDE_TIJD_DOOR_EINDE_SHIFT',
    PlanningswijzigingVanuitAqualex = 'PLANNINGSWIJZIGING_VANUIT_AQUALEX',
    AndereTechnicusWerktVerder = 'ANDERE_TECHNICUS_WERKT_VERDER',
    Andere = 'ANDERE'
}

/**
 * 
 * @export
 * @interface CheckOutVanToestel
 */
export interface CheckOutVanToestel {
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanToestel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanToestel
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof CheckOutVanToestel
     */
    toestelId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CheckOutVanToestel
     */
    nietUitgevoerdWerk: Array<string>;
}

/**
 * 
 * @export
 * @interface Co2Geleverd
 */
export interface Co2Geleverd {
    /**
     * 
     * @type {string}
     * @memberof Co2Geleverd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof Co2Geleverd
     */
    technicusId: string;
    /**
     * 
     * @type {Array<GeleverdeCo2Artikels>}
     * @memberof Co2Geleverd
     */
    leveringen?: Array<GeleverdeCo2Artikels>;
    /**
     * 
     * @type {boolean}
     * @memberof Co2Geleverd
     */
    toestemmingKlantAfwijkendeBestelling?: boolean;
}

/**
 * 
 * @export
 * @interface Co2Levering
 */
export interface Co2Levering {
    /**
     * 
     * @type {string}
     * @memberof Co2Levering
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof Co2Levering
     */
    gevraagdAantal: number;
    /**
     * 
     * @type {number}
     * @memberof Co2Levering
     */
    gevraagdOmruilAantal?: number;
    /**
     * 
     * @type {number}
     * @memberof Co2Levering
     */
    gevraagdExtraAantal?: number;
    /**
     * 
     * @type {string}
     * @memberof Co2Levering
     */
    klantReferentie?: string;
    /**
     * 
     * @type {Co2LeveringOorsprong}
     * @memberof Co2Levering
     */
    oorsprong: Co2LeveringOorsprong;
}

/**
 * 
 * @export
 * @interface Co2LeveringOorsprong
 */
export interface Co2LeveringOorsprong {
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    type?: Co2LeveringOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    prestatieId?: string;
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    bestellingId?: string;
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    bestellingNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    verkoopOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof Co2LeveringOorsprong
     */
    verkoopOrderPlatformId?: string;
}

/**
* @export
* @enum {string}
*/
export enum Co2LeveringOorsprongTypeEnum {
    PlatformPrestatie = 'PLATFORM_PRESTATIE',
    DynamicsOrderFromAptus = 'DYNAMICS_ORDER_FROM_APTUS'
}

/**
 * 
 * @export
 * @interface CoordinaatRestModel
 */
export interface CoordinaatRestModel {
    /**
     * 
     * @type {number}
     * @memberof CoordinaatRestModel
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof CoordinaatRestModel
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof CoordinaatRestModel
     */
    type?: CoordinaatRestModelTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CoordinaatRestModelTypeEnum {
    NavigerenNaar = 'NAVIGEREN_NAAR'
}

/**
 * 
 * @export
 * @interface DagBundleBezoek
 */
export interface DagBundleBezoek {
    /**
     * 
     * @type {string}
     * @memberof DagBundleBezoek
     */
    id: string;
    /**
     * 
     * @type {Array<BezoekSessieEventMessage>}
     * @memberof DagBundleBezoek
     */
    events: Array<BezoekSessieEventMessage>;
}

/**
 * 
 * @export
 * @interface DagBundleBezoekVersieRestModel
 */
export interface DagBundleBezoekVersieRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagBundleBezoekVersieRestModel
     */
    bezoekSessieId: string;
    /**
     * 
     * @type {string}
     * @memberof DagBundleBezoekVersieRestModel
     */
    versieTijdstip: string;
}

/**
 * 
 * @export
 * @interface DagBundleRestModel
 */
export interface DagBundleRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagBundleRestModel
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof DagBundleRestModel
     */
    datum: string;
    /**
     * 
     * @type {DagPlanningRestModel}
     * @memberof DagBundleRestModel
     */
    dagPlanning: DagPlanningRestModel;
    /**
     * 
     * @type {Array<ServiceAdres>}
     * @memberof DagBundleRestModel
     */
    serviceAdressen: Array<ServiceAdres>;
    /**
     * 
     * @type {Array<Klant>}
     * @memberof DagBundleRestModel
     */
    klanten: Array<Klant>;
    /**
     * 
     * @type {Array<Toestel>}
     * @memberof DagBundleRestModel
     */
    toestellen: Array<Toestel>;
    /**
     * 
     * @type {Array<DagBundleBezoek>}
     * @memberof DagBundleRestModel
     */
    bezoeken: Array<DagBundleBezoek>;
}

/**
 * 
 * @export
 * @interface DagBundleVersieRestModel
 */
export interface DagBundleVersieRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagBundleVersieRestModel
     */
    dagPlanningId: string;
    /**
     * 
     * @type {string}
     * @memberof DagBundleVersieRestModel
     */
    dagPlanningVersieTijdstip: string;
    /**
     * 
     * @type {Array<DagBundleBezoekVersieRestModel>}
     * @memberof DagBundleVersieRestModel
     */
    bezoeken: Array<DagBundleBezoekVersieRestModel>;
}

/**
 * 
 * @export
 * @interface DagPlanningBezoekEntryRestModel
 */
export interface DagPlanningBezoekEntryRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    bezoekInstructie: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    serviceAdresId: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    bezoekId: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    toekomstigBezoekSessieId: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    versieTijdstip: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    duurtijd?: number;
    /**
     * 
     * @type {UitTeVoerenWerk}
     * @memberof DagPlanningBezoekEntryRestModel
     */
    uitTeVoerenWerk: UitTeVoerenWerk;
}

/**
 * 
 * @export
 * @interface DagPlanningBezoekRestModel
 */
export interface DagPlanningBezoekRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekRestModel
     */
    serviceAdresId: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekRestModel
     */
    bezoekId: string;
    /**
     * 
     * @type {UitTeVoerenWerk}
     * @memberof DagPlanningBezoekRestModel
     */
    uitTeVoerenWerk: UitTeVoerenWerk;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekRestModel
     */
    versieTijdstip: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningBezoekRestModel
     */
    bezoekInstructie: string;
}

/**
 * 
 * @export
 * @interface DagPlanningHeaderRestModel
 */
export interface DagPlanningHeaderRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    header?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningHeaderRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningHeaderRestModel
     */
    duurtijd?: number;
}

/**
 * 
 * @export
 * @interface DagPlanningPauzeRestModel
 */
export interface DagPlanningPauzeRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningPauzeRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningPauzeRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningPauzeRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningPauzeRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningPauzeRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningPauzeRestModel
     */
    duurtijd?: number;
}

/**
 * 
 * @export
 * @interface DagPlanningRestModel
 */
export interface DagPlanningRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRestModel
     */
    status: DagPlanningRestModelStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRestModel
     */
    datum: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningRestModel
     */
    versie: number;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRestModel
     */
    versieTijdstip: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRestModel
     */
    technicusId: string;
    /**
     * 
     * @type {Array<AbstractDagPlanningEntryRestModel>}
     * @memberof DagPlanningRestModel
     */
    entries: Array<AbstractDagPlanningEntryRestModel>;
}

/**
* @export
* @enum {string}
*/
export enum DagPlanningRestModelStatusEnum {
    Ontwerp = 'ONTWERP',
    Vastgelegd = 'VASTGELEGD',
    Gearchiveerd = 'GEARCHIVEERD',
    NietsPlannen = 'NIETS_PLANNEN',
    BestaatNiet = 'BESTAAT_NIET'
}

/**
 * 
 * @export
 * @interface DagPlanningRitRestModel
 */
export interface DagPlanningRitRestModel {
    /**
     * 
     * @type {number}
     * @memberof DagPlanningRitRestModel
     */
    afstand?: number;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRitRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRitRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRitRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRitRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningRitRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningRitRestModel
     */
    duurtijd?: number;
}

/**
 * 
 * @export
 * @interface DagPlanningStopRestModel
 */
export interface DagPlanningStopRestModel {
    /**
     * 
     * @type {CoordinaatRestModel}
     * @memberof DagPlanningStopRestModel
     */
    coordinaat?: CoordinaatRestModel;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    plaats?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    adres2?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    landCode?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    type: DagPlanningStopRestModelTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningStopRestModel
     */
    duurtijd?: number;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    adres?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningStopRestModel
     */
    instructie?: string;
}

/**
* @export
* @enum {string}
*/
export enum DagPlanningStopRestModelTypeEnum {
    Vertrek = 'VERTREK',
    Stop = 'STOP',
    Bestemming = 'BESTEMMING'
}

/**
 * 
 * @export
 * @interface DagPlanningTextRestModel
 */
export interface DagPlanningTextRestModel {
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    geplandStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    geplandEind?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof DagPlanningTextRestModel
     */
    displayStart?: string;
    /**
     * 
     * @type {number}
     * @memberof DagPlanningTextRestModel
     */
    duurtijd?: number;
}

/**
 * 
 * @export
 * @interface DeviceInfoGelogd
 */
export interface DeviceInfoGelogd {
    /**
     * 
     * @type {string}
     * @memberof DeviceInfoGelogd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfoGelogd
     */
    userAgent: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceInfoGelogd
     */
    batteryPercentage?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfoGelogd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfoGelogd
     */
    instanceId: string;
}

/**
 * 
 * @export
 * @interface EventMetadata
 */
export interface EventMetadata {
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    creatieTijdstip: string;
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    ontvangenTijdstip?: string;
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    aangemaaktDoor?: string;
    /**
     * 
     * @type {EventOorsprong}
     * @memberof EventMetadata
     */
    oorsprong: EventOorsprong;
    /**
     * 
     * @type {string}
     * @memberof EventMetadata
     */
    instanceId: string;
}

/**
 * 
 * @export
 * @interface EventOorsprong
 */
export interface EventOorsprong {
    /**
     * 
     * @type {string}
     * @memberof EventOorsprong
     */
    type: EventOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof EventOorsprong
     */
    versie: string;
}

/**
* @export
* @enum {string}
*/
export enum EventOorsprongTypeEnum {
    Ui = 'FIELD_SERVICE_UI',
    Be = 'FIELD_SERVICE_BE'
}

/**
 * 
 * @export
 * @interface ExtraEmailAdres
 */
export interface ExtraEmailAdres {
    /**
     * 
     * @type {string}
     * @memberof ExtraEmailAdres
     */
    emailAdres?: string;
}

/**
 * 
 * @export
 * @interface FSInstallatieSituatieFotoId
 */
export interface FSInstallatieSituatieFotoId {
    /**
     * 
     * @type {string}
     * @memberof FSInstallatieSituatieFotoId
     */
    identifier?: string;
}

/**
 * 
 * @export
 * @interface GeinstalleerdComponent
 */
export interface GeinstalleerdComponent {
    /**
     * 
     * @type {string}
     * @memberof GeinstalleerdComponent
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof GeinstalleerdComponent
     */
    aantal: number;
    /**
     * 
     * @type {string}
     * @memberof GeinstalleerdComponent
     */
    serieNummer?: string;
}

/**
 * 
 * @export
 * @interface GeleverdeCo2Artikels
 */
export interface GeleverdeCo2Artikels {
    /**
     * 
     * @type {string}
     * @memberof GeleverdeCo2Artikels
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof GeleverdeCo2Artikels
     */
    aantal?: number;
    /**
     * 
     * @type {number}
     * @memberof GeleverdeCo2Artikels
     */
    aantalRetour?: number;
    /**
     * 
     * @type {string}
     * @memberof GeleverdeCo2Artikels
     */
    onvolledigeLeveringReden?: GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum;
}

/**
* @export
* @enum {string}
*/
export enum GeleverdeCo2ArtikelsOnvolledigeLeveringRedenEnum {
    TeWeinigFlessenBeschikbaar = 'TE_WEINIG_FLESSEN_BESCHIKBAAR',
    AndereSoortFlessenGeleverd = 'ANDERE_SOORT_FLESSEN_GELEVERD',
    KlantAkkoord = 'KLANT_AKKOORD',
    VraagKlant = 'VRAAG_KLANT',
    KlantKanTeWeinigCo2FlessenOmruilen = 'KLANT_KAN_TE_WEINIG_CO2_FLESSEN_OMRUILEN'
}

/**
 * 
 * @export
 * @interface GpsLocatieGelogd
 */
export interface GpsLocatieGelogd {
    /**
     * 
     * @type {string}
     * @memberof GpsLocatieGelogd
     */
    _type?: string;
    /**
     * 
     * @type {number}
     * @memberof GpsLocatieGelogd
     */
    accuracy: number;
    /**
     * 
     * @type {string}
     * @memberof GpsLocatieGelogd
     */
    technicusId: string;
    /**
     * 
     * @type {number}
     * @memberof GpsLocatieGelogd
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof GpsLocatieGelogd
     */
    longitude: number;
    /**
     * 
     * @type {string}
     * @memberof GpsLocatieGelogd
     */
    oorsprong: GpsLocatieGelogdOorsprongEnum;
}

/**
* @export
* @enum {string}
*/
export enum GpsLocatieGelogdOorsprongEnum {
    Onderweg = 'ONDERWEG',
    CheckIn = 'CHECK_IN',
    CheckOut = 'CHECK_OUT'
}

/**
 * 
 * @export
 * @interface HandtekeningGezet
 */
export interface HandtekeningGezet {
    /**
     * 
     * @type {Array<ExtraEmailAdres>}
     * @memberof HandtekeningGezet
     */
    extraEmailAdressen: Array<ExtraEmailAdres>;
    /**
     * 
     * @type {string}
     * @memberof HandtekeningGezet
     */
    ondertekenaar: string;
    /**
     * 
     * @type {string}
     * @memberof HandtekeningGezet
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof HandtekeningGezet
     */
    handtekening: string;
    /**
     * 
     * @type {string}
     * @memberof HandtekeningGezet
     */
    technicusId: string;
    /**
     * 
     * @type {Array<PredefinedEmailAdres>}
     * @memberof HandtekeningGezet
     */
    predefinedEmailAdressen: Array<PredefinedEmailAdres>;
    /**
     * 
     * @type {string}
     * @memberof HandtekeningGezet
     */
    opmerkingKlant?: string;
}

/**
 * 
 * @export
 * @interface Installatie
 */
export interface Installatie {
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    teInstallerenArtikelId: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    teInstallerenArtikelNummer: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    teInstallerenArtikelOmschrijving: string;
    /**
     * 
     * @type {Array<InstallatieComponent>}
     * @memberof Installatie
     */
    teInstallerenComponenten: Array<InstallatieComponent>;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    locatie?: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    gebouw?: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    verdieping?: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    lokaalNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    verkoperNaam?: string;
    /**
     * 
     * @type {Array<InstallatieCo2>}
     * @memberof Installatie
     */
    co2Artikelen: Array<InstallatieCo2>;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    techniekerInstructies?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Installatie
     */
    waterOpDeLeiding?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Installatie
     */
    elektriciteitAanwezig?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Installatie
     */
    algemeneOpmerking?: string;
    /**
     * 
     * @type {Array<InstallatieBijlage>}
     * @memberof Installatie
     */
    bijlagen: Array<InstallatieBijlage>;
    /**
     * 
     * @type {Array<InstallatieSituatieFoto>}
     * @memberof Installatie
     */
    situatieFotos: Array<InstallatieSituatieFoto>;
    /**
     * 
     * @type {InstallatieOorsprong}
     * @memberof Installatie
     */
    oorsprong: InstallatieOorsprong;
}

/**
 * 
 * @export
 * @interface InstallatieBijlage
 */
export interface InstallatieBijlage {
    /**
     * 
     * @type {string}
     * @memberof InstallatieBijlage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieBijlage
     */
    naam?: string;
}

/**
 * 
 * @export
 * @interface InstallatieCo2
 */
export interface InstallatieCo2 {
    /**
     * 
     * @type {string}
     * @memberof InstallatieCo2
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieCo2
     */
    artikelNr: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieCo2
     */
    artikelOmschrijving: string;
    /**
     * 
     * @type {number}
     * @memberof InstallatieCo2
     */
    aantalTeLeveren: number;
}

/**
 * 
 * @export
 * @interface InstallatieComponent
 */
export interface InstallatieComponent {
    /**
     * 
     * @type {string}
     * @memberof InstallatieComponent
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieComponent
     */
    artikelNr: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieComponent
     */
    artikelOmschrijving: string;
    /**
     * 
     * @type {number}
     * @memberof InstallatieComponent
     */
    aantal: number;
    /**
     * 
     * @type {boolean}
     * @memberof InstallatieComponent
     */
    serieNummerOpvragen: boolean;
}

/**
 * 
 * @export
 * @interface InstallatieOorsprong
 */
export interface InstallatieOorsprong {
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    type?: InstallatieOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    prestatieId?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    verkoopOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    verkoopOrderPlatformId?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    assemblageOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieOorsprong
     */
    assemblageOrderPlatformId?: string;
}

/**
* @export
* @enum {string}
*/
export enum InstallatieOorsprongTypeEnum {
    PlatformPrestatie = 'PLATFORM_PRESTATIE',
    DynamicsOrderFromAptus = 'DYNAMICS_ORDER_FROM_APTUS'
}

/**
 * 
 * @export
 * @interface InstallatieSituatieFoto
 */
export interface InstallatieSituatieFoto {
    /**
     * 
     * @type {FSInstallatieSituatieFotoId}
     * @memberof InstallatieSituatieFoto
     */
    id?: FSInstallatieSituatieFotoId;
    /**
     * 
     * @type {string}
     * @memberof InstallatieSituatieFoto
     */
    naam?: string;
}

/**
 * 
 * @export
 * @interface InstallatieTijdIngegeven
 */
export interface InstallatieTijdIngegeven {
    /**
     * 
     * @type {string}
     * @memberof InstallatieTijdIngegeven
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieTijdIngegeven
     */
    vanTijd: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieTijdIngegeven
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieTijdIngegeven
     */
    installatieId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieTijdIngegeven
     */
    totTijd?: string;
}

/**
 * 
 * @export
 * @interface InstallatieUitgevoerd
 */
export interface InstallatieUitgevoerd {
    /**
     * 
     * @type {Array<InstallatieUitgevoerdCo2Artikel>}
     * @memberof InstallatieUitgevoerd
     */
    co2Artikelen: Array<InstallatieUitgevoerdCo2Artikel>;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    locatie?: string;
    /**
     * 
     * @type {Array<GeinstalleerdComponent>}
     * @memberof InstallatieUitgevoerd
     */
    geinstalleerdeComponenten: Array<GeinstalleerdComponent>;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    nietGeinstalleerdReden?: InstallatieUitgevoerdNietGeinstalleerdRedenEnum;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    gebouw?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    reden?: InstallatieUitgevoerdRedenEnum;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    verdieping?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    lokaalNummer?: string;
    /**
     * 
     * @type {Array<InstallatieUitgevoerdVerbruiktArtikel>}
     * @memberof InstallatieUitgevoerd
     */
    verbruikteArtikelen: Array<InstallatieUitgevoerdVerbruiktArtikel>;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    opmerking?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    installatieId: string;
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerd
     */
    resultaat: InstallatieUitgevoerdResultaatEnum;
    /**
     * 
     * @type {Array<InstallatieUitgevoerdOmgevingsFoto>}
     * @memberof InstallatieUitgevoerd
     */
    fotos: Array<InstallatieUitgevoerdOmgevingsFoto>;
}

/**
* @export
* @enum {string}
*/
export enum InstallatieUitgevoerdNietGeinstalleerdRedenEnum {
    NogGeenWater = 'NOG_GEEN_WATER',
    NogGeenElektriciteit = 'NOG_GEEN_ELEKTRICITEIT',
    NogGeenWerkblad = 'NOG_GEEN_WERKBLAD',
    NodigeUitsparingenInWerkbladNietKlaar = 'NODIGE_UITSPARINGEN_IN_WERKBLAD_NIET_KLAAR',
    VerkeerdeKraanOfToestelMee = 'VERKEERDE_KRAAN_OF_TOESTEL_MEE',
    TeInstallerenToestelWerktNietWaardoorRetour = 'TE_INSTALLEREN_TOESTEL_WERKT_NIET_WAARDOOR_RETOUR',
    ToestelTeGroot = 'TOESTEL_TE_GROOT',
    Andere = 'ANDERE'
}/**
* @export
* @enum {string}
*/
export enum InstallatieUitgevoerdRedenEnum {
    NogGeenWater = 'NOG_GEEN_WATER',
    NogGeenElektriciteit = 'NOG_GEEN_ELEKTRICITEIT',
    NogGeenWerkblad = 'NOG_GEEN_WERKBLAD',
    NodigeUitpsaringenInWerkbladNietKlaar = 'NODIGE_UITPSARINGEN_IN_WERKBLAD_NIET_KLAAR',
    PompbakNogNietGeinstalleerd = 'POMPBAK_NOG_NIET_GEINSTALLEERD',
    AfvoerNogNietAangesloten = 'AFVOER_NOG_NIET_AANGESLOTEN',
    VerkeerdeKraanOfToestelMee = 'VERKEERDE_KRAAN_OF_TOESTEL_MEE',
    TeInstallerenToestelWerktNietWaardoorRetour = 'TE_INSTALLEREN_TOESTEL_WERKT_NIET_WAARDOOR_RETOUR',
    KraanTeHoog = 'KRAAN_TE_HOOG',
    ToestelTeGroot = 'TOESTEL_TE_GROOT',
    Andere = 'ANDERE'
}/**
* @export
* @enum {string}
*/
export enum InstallatieUitgevoerdResultaatEnum {
    VolledigAfgewerkt = 'VOLLEDIG_AFGEWERKT',
    NogNietOperationeel = 'NOG_NIET_OPERATIONEEL',
    NietGeinstalleerd = 'NIET_GEINSTALLEERD'
}

/**
 * 
 * @export
 * @interface InstallatieUitgevoerdCo2Artikel
 */
export interface InstallatieUitgevoerdCo2Artikel {
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerdCo2Artikel
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof InstallatieUitgevoerdCo2Artikel
     */
    aantalGeleverd: number;
}

/**
 * 
 * @export
 * @interface InstallatieUitgevoerdOmgevingsFoto
 */
export interface InstallatieUitgevoerdOmgevingsFoto {
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerdOmgevingsFoto
     */
    uploadId?: string;
}

/**
 * 
 * @export
 * @interface InstallatieUitgevoerdVerbruiktArtikel
 */
export interface InstallatieUitgevoerdVerbruiktArtikel {
    /**
     * 
     * @type {string}
     * @memberof InstallatieUitgevoerdVerbruiktArtikel
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof InstallatieUitgevoerdVerbruiktArtikel
     */
    aantal: number;
}

/**
 * 
 * @export
 * @interface InterneFeedbackToegevoegd
 */
export interface InterneFeedbackToegevoegd {
    /**
     * 
     * @type {string}
     * @memberof InterneFeedbackToegevoegd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof InterneFeedbackToegevoegd
     */
    opmerking?: string;
    /**
     * 
     * @type {string}
     * @memberof InterneFeedbackToegevoegd
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface InterventieTijdIngegeven
 */
export interface InterventieTijdIngegeven {
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    vanTijd: string;
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    interventieId: string;
    /**
     * 
     * @type {string}
     * @memberof InterventieTijdIngegeven
     */
    totTijd?: string;
}

/**
 * 
 * @export
 * @interface Klant
 */
export interface Klant {
    /**
     * 
     * @type {string}
     * @memberof Klant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Klant
     */
    nr?: string;
    /**
     * 
     * @type {string}
     * @memberof Klant
     */
    naam?: string;
    /**
     * 
     * @type {string}
     * @memberof Klant
     */
    extraCo2FlesBeleid?: KlantExtraCo2FlesBeleidEnum;
}

/**
* @export
* @enum {string}
*/
export enum KlantExtraCo2FlesBeleidEnum {
    Inherit = 'INHERIT',
    Gebruiksrecht = 'GEBRUIKSRECHT',
    Waarborg = 'WAARBORG',
    Geen = 'GEEN'
}

/**
 * 
 * @export
 * @interface OnderwegNaarAdres
 */
export interface OnderwegNaarAdres {
    /**
     * 
     * @type {string}
     * @memberof OnderwegNaarAdres
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof OnderwegNaarAdres
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface PredefinedEmailAdres
 */
export interface PredefinedEmailAdres {
    /**
     * 
     * @type {string}
     * @memberof PredefinedEmailAdres
     */
    emailAdres?: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedEmailAdres
     */
    actie?: PredefinedEmailAdresActieEnum;
}

/**
* @export
* @enum {string}
*/
export enum PredefinedEmailAdresActieEnum {
    Verzenden = 'VERZENDEN',
    Verwijderen = 'VERWIJDEREN'
}

/**
 * 
 * @export
 * @interface ServiceAdres
 */
export interface ServiceAdres {
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    klantId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    adres?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    adres2?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    postcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    plaats?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    landCode?: string;
    /**
     * 
     * @type {ServiceAdresOpeningsurenRestModel}
     * @memberof ServiceAdres
     */
    openingsuren?: ServiceAdresOpeningsurenRestModel;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    contactNaam?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    contactTelefoon?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceAdres
     */
    contactEmailAdressen?: Array<string>;
    /**
     * 
     * @type {Array<CoordinaatRestModel>}
     * @memberof ServiceAdres
     */
    coordinaten: Array<CoordinaatRestModel>;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    vasteInstructie?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceAdres
     */
    toestelIds: Array<string>;
    /**
     * 
     * @type {Array<ServiceAdresServiceRapportRestModel>}
     * @memberof ServiceAdres
     */
    serviceRapporten: Array<ServiceAdresServiceRapportRestModel>;
    /**
     * 
     * @type {Array<ServiceAdresBijlageRestModel>}
     * @memberof ServiceAdres
     */
    bijlagen: Array<ServiceAdresBijlageRestModel>;
    /**
     * 
     * @type {Array<ServiceAdresCo2ArtikelRestModel>}
     * @memberof ServiceAdres
     */
    co2Artikelen: Array<ServiceAdresCo2ArtikelRestModel>;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdres
     */
    bedrijfId: string;
}

/**
 * 
 * @export
 * @interface ServiceAdresBijlageRestModel
 */
export interface ServiceAdresBijlageRestModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresBijlageRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresBijlageRestModel
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresBijlageRestModel
     */
    omschrijving?: string;
}

/**
 * 
 * @export
 * @interface ServiceAdresCo2ArtikelRestModel
 */
export interface ServiceAdresCo2ArtikelRestModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    nummer: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    type: ServiceAdresCo2ArtikelRestModelTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    bedrijfId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    afbeeldingUri?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    hervulbaar: boolean;
    /**
     * 
     * @type {number}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    inhoud?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    vervangbaar?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    installeerbaar?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    onderdeelSoort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceAdresCo2ArtikelRestModel
     */
    inGebruik: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ServiceAdresCo2ArtikelRestModelTypeEnum {
    Algemeen = 'ALGEMEEN',
    Filter = 'FILTER',
    Co2 = 'CO2'
}

/**
 * 
 * @export
 * @interface ServiceAdresDagOpeningsurenRestModel
 */
export interface ServiceAdresDagOpeningsurenRestModel {
    /**
     * 
     * @type {TijdRange}
     * @memberof ServiceAdresDagOpeningsurenRestModel
     */
    slot1?: TijdRange;
    /**
     * 
     * @type {TijdRange}
     * @memberof ServiceAdresDagOpeningsurenRestModel
     */
    slot2?: TijdRange;
}

/**
 * 
 * @export
 * @interface ServiceAdresOpeningsurenRestModel
 */
export interface ServiceAdresOpeningsurenRestModel {
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    maandag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    dinsdag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    woensdag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    donderdag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    vrijdag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    zaterdag?: ServiceAdresDagOpeningsurenRestModel;
    /**
     * 
     * @type {ServiceAdresDagOpeningsurenRestModel}
     * @memberof ServiceAdresOpeningsurenRestModel
     */
    zondag?: ServiceAdresDagOpeningsurenRestModel;
}

/**
 * 
 * @export
 * @interface ServiceAdresServiceRapportRestModel
 */
export interface ServiceAdresServiceRapportRestModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresServiceRapportRestModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresServiceRapportRestModel
     */
    tijdstip?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceAdresServiceRapportRestModel
     */
    titel?: string;
}

/**
 * 
 * @export
 * @interface ServiceOrderReferenceRestModel
 */
export interface ServiceOrderReferenceRestModel {
    /**
     * 
     * @type {string}
     * @memberof ServiceOrderReferenceRestModel
     */
    serviceOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceOrderReferenceRestModel
     */
    serviceOrderSoort?: string;
}

/**
 * 
 * @export
 * @interface Shift
 */
export interface Shift {
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    datum: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    beginuur: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    einduur: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    vertrekAdres?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    vertrekAdres2?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    vertrekPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    vertrekPlaats?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    vertrekLandCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    eindAdres?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    eindAdres2?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    eindPostcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    eindPlaats?: string;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    eindLandCode?: string;
}

/**
 * 
 * @export
 * @interface StukToegevoegd
 */
export interface StukToegevoegd {
    /**
     * 
     * @type {string}
     * @memberof StukToegevoegd
     */
    artikelId: string;
}

/**
 * 
 * @export
 * @interface StukVerwijderd
 */
export interface StukVerwijderd {
    /**
     * 
     * @type {string}
     * @memberof StukVerwijderd
     */
    id: string;
}

/**
 * 
 * @export
 * @interface StukVerwisseld
 */
export interface StukVerwisseld {
    /**
     * 
     * @type {string}
     * @memberof StukVerwisseld
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof StukVerwisseld
     */
    nieuwArtikelId: string;
}

/**
 * 
 * @export
 * @interface Technicus
 */
export interface Technicus {
    /**
     * 
     * @type {string}
     * @memberof Technicus
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Technicus
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof Technicus
     */
    taal: string;
}

/**
 * 
 * @export
 * @interface TechnicusToegevoegd
 */
export interface TechnicusToegevoegd {
    /**
     * 
     * @type {string}
     * @memberof TechnicusToegevoegd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicusToegevoegd
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface TechnicusVerwijderd
 */
export interface TechnicusVerwijderd {
    /**
     * 
     * @type {string}
     * @memberof TechnicusVerwijderd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof TechnicusVerwijderd
     */
    technicusId: string;
}

/**
 * 
 * @export
 * @interface TijdRange
 */
export interface TijdRange {
    /**
     * 
     * @type {string}
     * @memberof TijdRange
     */
    van?: string;
    /**
     * 
     * @type {string}
     * @memberof TijdRange
     */
    tot?: string;
    /**
     * 
     * @type {number}
     * @memberof TijdRange
     */
    duur?: number;
}

/**
 * 
 * @export
 * @interface Toestel
 */
export interface Toestel {
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    nr: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    serieNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    installatieDatum?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    locatieBeschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    gebouw?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    verdieping?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    lokaalnummer?: string;
    /**
     * 
     * @type {Array<ToestelComponent>}
     * @memberof Toestel
     */
    componenten?: Array<ToestelComponent>;
    /**
     * 
     * @type {Array<ToestelBijlage>}
     * @memberof Toestel
     */
    bijlagen?: Array<ToestelBijlage>;
    /**
     * 
     * @type {Array<ToestelInGebruikServiceLogEntryRestModel | ToestelInstallatieServiceLogEntryRestModel | ToestelInterventieServiceLogEntryRestModel | ToestelOnderhoudServiceLogEntryRestModel | ToestelOpmerkingServiceLogEntryRestModel | ToestelUitDienstServiceLogEntryRestModel | ToestelWatermeterStandServiceLogEntryRestModel>}
     * @memberof Toestel
     */
    serviceLogEntries?: Array<ToestelInGebruikServiceLogEntryRestModel | ToestelInstallatieServiceLogEntryRestModel | ToestelInterventieServiceLogEntryRestModel | ToestelOnderhoudServiceLogEntryRestModel | ToestelOpmerkingServiceLogEntryRestModel | ToestelUitDienstServiceLogEntryRestModel | ToestelWatermeterStandServiceLogEntryRestModel>;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    transactieType?: ToestelTransactieTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    afbeeldingUri?: string;
    /**
     * 
     * @type {ToestelWaterTellerRestModel}
     * @memberof Toestel
     */
    waterTeller: ToestelWaterTellerRestModel;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    artikelNr?: string;
    /**
     * 
     * @type {string}
     * @memberof Toestel
     */
    artikelOmschrijving?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Toestel
     */
    uitDienst?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ToestelTransactieTypeEnum {
    Huren = 'HUREN',
    Kopen = 'KOPEN',
    NietZelfVerkocht = 'NIET_ZELF_VERKOCHT',
    Onbekend = 'ONBEKEND'
}

/**
 * 
 * @export
 * @interface ToestelBijlage
 */
export interface ToestelBijlage {
    /**
     * 
     * @type {string}
     * @memberof ToestelBijlage
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelBijlage
     */
    type: ToestelBijlageTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelBijlage
     */
    naam: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelBijlage
     */
    omschrijving?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelBijlageTypeEnum {
    Foto = 'FOTO',
    CoverFoto = 'COVER_FOTO',
    Bestand = 'BESTAND'
}

/**
 * 
 * @export
 * @interface ToestelComponent
 */
export interface ToestelComponent {
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ToestelComponent
     */
    lijnNr: number;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    artikelNr?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    serienummer?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    installatieDatum?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    gewijzigdOp?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelComponent
     */
    artikelId?: string;
}

/**
 * 
 * @export
 * @interface ToestelFotoToegevoegd
 */
export interface ToestelFotoToegevoegd {
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoToegevoegd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoToegevoegd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoToegevoegd
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoToegevoegd
     */
    uploadId: string;
}

/**
 * 
 * @export
 * @interface ToestelFotoVerwijderd
 */
export interface ToestelFotoVerwijderd {
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoVerwijderd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoVerwijderd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoVerwijderd
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelFotoVerwijderd
     */
    uploadId: string;
}

/**
 * 
 * @export
 * @interface ToestelInGebruikServiceLogEntryRestModel
 */
export interface ToestelInGebruikServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {string}
     * @memberof ToestelInGebruikServiceLogEntryRestModel
     */
    omschrijving?: string;
}

/**
 * 
 * @export
 * @interface ToestelInGebruikServiceLogEntryRestModelAllOf
 */
export interface ToestelInGebruikServiceLogEntryRestModelAllOf {
    /**
     * 
     * @type {string}
     * @memberof ToestelInGebruikServiceLogEntryRestModelAllOf
     */
    omschrijving?: string;
}

/**
 * 
 * @export
 * @interface ToestelInformatieAangepast
 */
export interface ToestelInformatieAangepast {
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    lokaalOrigineel?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    locatieBeschrijvingOrigineel?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    gebouwOrigineel?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    serieNummerOrigineel?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    gebouw?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    serieNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    verdieping?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    verdiepingOrigineel?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    opmerking?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    locatieBeschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInformatieAangepast
     */
    lokaal?: string;
}

/**
 * 
 * @export
 * @interface ToestelInstallatieServiceLogEntryRestModel
 */
export interface ToestelInstallatieServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
}

/**
 * 
 * @export
 * @interface ToestelInterventie
 */
export interface ToestelInterventie {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventie
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventie
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventie
     */
    taakType?: ToestelInterventieTaakTypeEnum;
    /**
     * 
     * @type {Array<ToestelInterventieMeeTeGevenArtikel>}
     * @memberof ToestelInterventie
     */
    meeTeGevenArtikels?: Array<ToestelInterventieMeeTeGevenArtikel>;
    /**
     * 
     * @type {ToestelInterventieOorsprong}
     * @memberof ToestelInterventie
     */
    oorsprong: ToestelInterventieOorsprong;
}

/**
* @export
* @enum {string}
*/
export enum ToestelInterventieTaakTypeEnum {
    Herstelling = 'HERSTELLING',
    Upgrade = 'UPGRADE',
    Verplaatsing = 'VERPLAATSING',
    Verhuis = 'VERHUIS',
    Ophaling = 'OPHALING',
    StaalnameLaboOnderzoek = 'STAALNAME_LABO_ONDERZOEK',
    Advies = 'ADVIES',
    Opstart = 'OPSTART',
    RolloutRecallActie = 'ROLLOUT_RECALL_ACTIE'
}

/**
 * 
 * @export
 * @interface ToestelInterventieMeeTeGevenArtikel
 */
export interface ToestelInterventieMeeTeGevenArtikel {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieMeeTeGevenArtikel
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieMeeTeGevenArtikel
     */
    artikelOmschrijving: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieMeeTeGevenArtikel
     */
    artikelNr: string;
    /**
     * 
     * @type {number}
     * @memberof ToestelInterventieMeeTeGevenArtikel
     */
    aantal?: number;
}

/**
 * 
 * @export
 * @interface ToestelInterventieOorsprong
 */
export interface ToestelInterventieOorsprong {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    type?: ToestelInterventieOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    prestatieId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    interventieId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    serviceOrderSoort?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    serviceOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    serviceOrderPlatformId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieOorsprong
     */
    bestellingNummer?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelInterventieOorsprongTypeEnum {
    PlatformPrestatie = 'PLATFORM_PRESTATIE',
    DynamicsOrderFromAptus = 'DYNAMICS_ORDER_FROM_APTUS'
}

/**
 * 
 * @export
 * @interface ToestelInterventieServiceLogEntryRestModel
 */
export interface ToestelInterventieServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModel
     */
    serviceOrderNr: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModel
     */
    serviceOrderSoort: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModel
     */
    omschrijving: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModel
     */
    resultaat: ToestelInterventieServiceLogEntryRestModelResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModel
     */
    resultaatOpmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelInterventieServiceLogEntryRestModelResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelInterventieServiceLogEntryRestModelAllOf
 */
export interface ToestelInterventieServiceLogEntryRestModelAllOf {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModelAllOf
     */
    serviceOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModelAllOf
     */
    serviceOrderSoort?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModelAllOf
     */
    omschrijving?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModelAllOf
     */
    resultaat?: ToestelInterventieServiceLogEntryRestModelAllOfResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieServiceLogEntryRestModelAllOf
     */
    resultaatOpmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelInterventieServiceLogEntryRestModelAllOfResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelInterventieUitgevoerd
 */
export interface ToestelInterventieUitgevoerd {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    toestelId: string;
    /**
     * 
     * @type {ToestelInterventieUitgevoerdControleWerking}
     * @memberof ToestelInterventieUitgevoerd
     */
    controleWerking?: ToestelInterventieUitgevoerdControleWerking;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    interventieId: string;
    /**
     * 
     * @type {Set<ToestelInterventieUitgevoerdVerbruiktArtikel>}
     * @memberof ToestelInterventieUitgevoerd
     */
    verbruikteArtikelen: Set<ToestelInterventieUitgevoerdVerbruiktArtikel>;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    opmerking?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    soortCode: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerd
     */
    soortLabel: string;
}

/**
 * 
 * @export
 * @interface ToestelInterventieUitgevoerdControleWerking
 */
export interface ToestelInterventieUitgevoerdControleWerking {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerdControleWerking
     */
    resultaat?: ToestelInterventieUitgevoerdControleWerkingResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerdControleWerking
     */
    opmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelInterventieUitgevoerdControleWerkingResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelInterventieUitgevoerdVerbruiktArtikel
 */
export interface ToestelInterventieUitgevoerdVerbruiktArtikel {
    /**
     * 
     * @type {string}
     * @memberof ToestelInterventieUitgevoerdVerbruiktArtikel
     */
    artikelId: string;
    /**
     * 
     * @type {number}
     * @memberof ToestelInterventieUitgevoerdVerbruiktArtikel
     */
    aantal: number;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoud
 */
export interface ToestelOnderhoud {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoud
     */
    type?: string;
    /**
     * 
     * @type {ToestelOnderhoudStappen}
     * @memberof ToestelOnderhoud
     */
    stappen?: ToestelOnderhoudStappen;
    /**
     * 
     * @type {Array<ToestelOnderhoudOorsprong>}
     * @memberof ToestelOnderhoud
     */
    oorsprong: Array<ToestelOnderhoudOorsprong>;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudFilterVervangenRestModel
 */
export interface ToestelOnderhoudFilterVervangenRestModel {
    /**
     * 
     * @type {ArtikelRestModel}
     * @memberof ToestelOnderhoudFilterVervangenRestModel
     */
    artikel?: ArtikelRestModel;
    /**
     * 
     * @type {number}
     * @memberof ToestelOnderhoudFilterVervangenRestModel
     */
    lijnNr?: number;
    /**
     * 
     * @type {ArtikelRestModel}
     * @memberof ToestelOnderhoudFilterVervangenRestModel
     */
    nieuweFilterArtikel?: ArtikelRestModel;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudOorsprong
 */
export interface ToestelOnderhoudOorsprong {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudOorsprong
     */
    type?: ToestelOnderhoudOorsprongTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudOorsprong
     */
    prestatieId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudOorsprong
     */
    serviceOrderSoort?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudOorsprong
     */
    serviceOrderNr?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudOorsprong
     */
    serviceOrderPlatformId?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelOnderhoudOorsprongTypeEnum {
    PlatformPrestatie = 'PLATFORM_PRESTATIE',
    DynamicsOrderFromAptus = 'DYNAMICS_ORDER_FROM_APTUS'
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudServiceLogEntryRestModel
 */
export interface ToestelOnderhoudServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {Array<ServiceOrderReferenceRestModel>}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    serviceOrderReferences: Array<ServiceOrderReferenceRestModel>;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    onderhoudSoort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    reinigenEnSpoelen: boolean;
    /**
     * 
     * @type {Array<ToestelOnderhoudFilterVervangenRestModel>}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    filtersVervangen?: Array<ToestelOnderhoudFilterVervangenRestModel>;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    uvVervangen: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    co2Vervangen: boolean;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    resultaat: ToestelOnderhoudServiceLogEntryRestModelResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModel
     */
    resultaatOpmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelOnderhoudServiceLogEntryRestModelResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudServiceLogEntryRestModelAllOf
 */
export interface ToestelOnderhoudServiceLogEntryRestModelAllOf {
    /**
     * 
     * @type {Array<ServiceOrderReferenceRestModel>}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    serviceOrderReferences?: Array<ServiceOrderReferenceRestModel>;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    onderhoudSoort?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    reinigenEnSpoelen?: boolean;
    /**
     * 
     * @type {Array<ToestelOnderhoudFilterVervangenRestModel>}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    filtersVervangen?: Array<ToestelOnderhoudFilterVervangenRestModel>;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    uvVervangen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    co2Vervangen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    resultaat?: ToestelOnderhoudServiceLogEntryRestModelAllOfResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudServiceLogEntryRestModelAllOf
     */
    resultaatOpmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelOnderhoudServiceLogEntryRestModelAllOfResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudStappen
 */
export interface ToestelOnderhoudStappen {
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudStappen
     */
    reinigenEnSpoelen?: boolean;
    /**
     * 
     * @type {Array<ToestelOnderhoudTeVervangenFilter>}
     * @memberof ToestelOnderhoudStappen
     */
    filtersVervangen?: Array<ToestelOnderhoudTeVervangenFilter>;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudStappen
     */
    uvVervangen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudStappen
     */
    co2Vervangen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudStappen
     */
    tappuntWisselen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudStappen
     */
    controleWerking?: boolean;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudTeVervangenFilter
 */
export interface ToestelOnderhoudTeVervangenFilter {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    componentId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    artikelId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    artikelNummer: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    artikelOmschrijving?: string;
    /**
     * 
     * @type {number}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    lijnNr: number;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudTeVervangenFilter
     */
    installatieDatum?: string;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudUitgevoerd
 */
export interface ToestelOnderhoudUitgevoerd {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerd
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerd
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerd
     */
    toestelId: string;
    /**
     * 
     * @type {ToestelOnderhoudUitgevoerdStappen}
     * @memberof ToestelOnderhoudUitgevoerd
     */
    uitgevoerdeStappen?: ToestelOnderhoudUitgevoerdStappen;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudUitgevoerdControleWerking
 */
export interface ToestelOnderhoudUitgevoerdControleWerking {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerdControleWerking
     */
    resultaat?: ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerdControleWerking
     */
    opmerking?: string;
}

/**
* @export
* @enum {string}
*/
export enum ToestelOnderhoudUitgevoerdControleWerkingResultaatEnum {
    Oke = 'OKE',
    OkeOpvolgingVereist = 'OKE_OPVOLGING_VEREIST',
    Defect = 'DEFECT'
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudUitgevoerdFilter
 */
export interface ToestelOnderhoudUitgevoerdFilter {
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerdFilter
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelOnderhoudUitgevoerdFilter
     */
    nieuweFilterArtikelId?: string;
}

/**
 * 
 * @export
 * @interface ToestelOnderhoudUitgevoerdStappen
 */
export interface ToestelOnderhoudUitgevoerdStappen {
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    reinigenEnSpoelen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    uvVervangen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    co2Vervangen?: boolean;
    /**
     * 
     * @type {Array<ToestelOnderhoudUitgevoerdFilter>}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    filtersVervangen?: Array<ToestelOnderhoudUitgevoerdFilter>;
    /**
     * 
     * @type {ToestelOnderhoudUitgevoerdControleWerking}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    controleWerking?: ToestelOnderhoudUitgevoerdControleWerking;
    /**
     * 
     * @type {boolean}
     * @memberof ToestelOnderhoudUitgevoerdStappen
     */
    tappuntWisselen?: boolean;
}

/**
 * 
 * @export
 * @interface ToestelOpmerkingServiceLogEntryRestModel
 */
export interface ToestelOpmerkingServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
}

/**
 * 
 * @export
 * @interface ToestelServiceLogEntryRestModel
 */
export interface ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {string}
     * @memberof ToestelServiceLogEntryRestModel
     */
    tijdstip: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelServiceLogEntryRestModel
     */
    medewerkerId?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelServiceLogEntryRestModel
     */
    opmerking?: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelServiceLogEntryRestModel
     */
    _type: string;
}

/**
 * 
 * @export
 * @interface ToestelStuklijstAangepast
 */
export interface ToestelStuklijstAangepast {
    /**
     * 
     * @type {string}
     * @memberof ToestelStuklijstAangepast
     */
    _type?: string;
    /**
     * 
     * @type {Set<StukVerwisseld>}
     * @memberof ToestelStuklijstAangepast
     */
    verwisseldeStukken?: Set<StukVerwisseld>;
    /**
     * 
     * @type {string}
     * @memberof ToestelStuklijstAangepast
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof ToestelStuklijstAangepast
     */
    toestelId: string;
    /**
     * 
     * @type {Set<StukToegevoegd>}
     * @memberof ToestelStuklijstAangepast
     */
    toegevoegdeStukken?: Set<StukToegevoegd>;
    /**
     * 
     * @type {Set<StukVerwijderd>}
     * @memberof ToestelStuklijstAangepast
     */
    verwijderdeStukken?: Set<StukVerwijderd>;
}

/**
 * 
 * @export
 * @interface ToestelUitDienstServiceLogEntryRestModel
 */
export interface ToestelUitDienstServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {string}
     * @memberof ToestelUitDienstServiceLogEntryRestModel
     */
    omschrijving?: string;
}

/**
 * 
 * @export
 * @interface ToestelUitTeVoerenWerk
 */
export interface ToestelUitTeVoerenWerk {
    /**
     * 
     * @type {string}
     * @memberof ToestelUitTeVoerenWerk
     */
    toestelId: string;
    /**
     * 
     * @type {Array<ToestelInterventie>}
     * @memberof ToestelUitTeVoerenWerk
     */
    interventies: Array<ToestelInterventie>;
    /**
     * 
     * @type {ToestelOnderhoud}
     * @memberof ToestelUitTeVoerenWerk
     */
    onderhoud?: ToestelOnderhoud;
    /**
     * 
     * @type {string}
     * @memberof ToestelUitTeVoerenWerk
     */
    watermeterStandOpnameType: ToestelUitTeVoerenWerkWatermeterStandOpnameTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum ToestelUitTeVoerenWerkWatermeterStandOpnameTypeEnum {
    Verplicht = 'VERPLICHT',
    Optioneel = 'OPTIONEEL',
    NietOndersteund = 'NIET_ONDERSTEUND'
}

/**
 * 
 * @export
 * @interface ToestelWaterTellerOpnameRestModel
 */
export interface ToestelWaterTellerOpnameRestModel {
    /**
     * 
     * @type {string}
     * @memberof ToestelWaterTellerOpnameRestModel
     */
    opnameDatum?: string;
    /**
     * 
     * @type {number}
     * @memberof ToestelWaterTellerOpnameRestModel
     */
    opname?: number;
}

/**
 * 
 * @export
 * @interface ToestelWaterTellerRestModel
 */
export interface ToestelWaterTellerRestModel {
    /**
     * 
     * @type {boolean}
     * @memberof ToestelWaterTellerRestModel
     */
    bevatWaterTeller?: boolean;
    /**
     * 
     * @type {Array<ToestelWaterTellerOpnameRestModel>}
     * @memberof ToestelWaterTellerRestModel
     */
    recenteOpnames: Array<ToestelWaterTellerOpnameRestModel>;
}

/**
 * 
 * @export
 * @interface ToestelWatermeterStandServiceLogEntryRestModel
 */
export interface ToestelWatermeterStandServiceLogEntryRestModel extends ToestelServiceLogEntryRestModel {
    /**
     * 
     * @type {number}
     * @memberof ToestelWatermeterStandServiceLogEntryRestModel
     */
    meterstand: number;
}

/**
 * 
 * @export
 * @interface ToestelWatermeterStandServiceLogEntryRestModelAllOf
 */
export interface ToestelWatermeterStandServiceLogEntryRestModelAllOf {
    /**
     * 
     * @type {number}
     * @memberof ToestelWatermeterStandServiceLogEntryRestModelAllOf
     */
    meterstand?: number;
}

/**
 * 
 * @export
 * @interface UiFormDraft
 */
export interface UiFormDraft {
    /**
     * 
     * @type {string}
     * @memberof UiFormDraft
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof UiFormDraft
     */
    formId: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UiFormDraft
     */
    formValues: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof UiFormDraft
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof UiFormDraft
     */
    oorsprong: UiFormDraftOorsprongEnum;
}

/**
* @export
* @enum {string}
*/
export enum UiFormDraftOorsprongEnum {
    InputBlur = 'INPUT_BLUR',
    PageLeave = 'PAGE_LEAVE'
}

/**
 * 
 * @export
 * @interface UitTeVoerenWerk
 */
export interface UitTeVoerenWerk {
    /**
     * 
     * @type {Array<Co2Levering>}
     * @memberof UitTeVoerenWerk
     */
    co2Leveringen: Array<Co2Levering>;
    /**
     * 
     * @type {Array<AccessoireLevering>}
     * @memberof UitTeVoerenWerk
     */
    accessoireLeveringen: Array<AccessoireLevering>;
    /**
     * 
     * @type {Array<Installatie>}
     * @memberof UitTeVoerenWerk
     */
    installaties: Array<Installatie>;
    /**
     * 
     * @type {Array<ToestelUitTeVoerenWerk>}
     * @memberof UitTeVoerenWerk
     */
    toestellen: Array<ToestelUitTeVoerenWerk>;
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerk
     */
    co2BestellingTerPlaatseToegestaan: UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum;
}

/**
* @export
* @enum {string}
*/
export enum UitTeVoerenWerkCo2BestellingTerPlaatseToegestaanEnum {
    Ja = 'JA',
    NeenExternDocumentnrVerplicht = 'NEEN_EXTERN_DOCUMENTNR_VERPLICHT'
}

/**
 * 
 * @export
 * @interface UitTeVoerenWerkZonderBezoekSessie
 */
export interface UitTeVoerenWerkZonderBezoekSessie {
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    _type?: string;
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    datum: string;
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    bezoekId: string;
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    technicusId?: string;
    /**
     * 
     * @type {string}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    serviceAdresId?: string;
    /**
     * 
     * @type {UitTeVoerenWerk}
     * @memberof UitTeVoerenWerkZonderBezoekSessie
     */
    uitTeVoerenWerk: UitTeVoerenWerk;
}

/**
 * 
 * @export
 * @interface WaterstandOpgenomen
 */
export interface WaterstandOpgenomen {
    /**
     * 
     * @type {string}
     * @memberof WaterstandOpgenomen
     */
    _type?: string;
    /**
     * 
     * @type {number}
     * @memberof WaterstandOpgenomen
     */
    meterstand?: number;
    /**
     * 
     * @type {string}
     * @memberof WaterstandOpgenomen
     */
    technicusId: string;
    /**
     * 
     * @type {string}
     * @memberof WaterstandOpgenomen
     */
    toestelId: string;
    /**
     * 
     * @type {string}
     * @memberof WaterstandOpgenomen
     */
    resultaat: WaterstandOpgenomenResultaatEnum;
}

/**
* @export
* @enum {string}
*/
export enum WaterstandOpgenomenResultaatEnum {
    MeterstandOpgenomen = 'METERSTAND_OPGENOMEN',
    GeenMeterAanwezig = 'GEEN_METER_AANWEZIG'
}
