import React, {ReactNode, useCallback, useEffect, useState} from "react";
import {MsalProvider, useAccount, useMsal} from "@azure/msal-react";

import {AccountInfo, AuthenticationResult, EventMessage, EventType} from "@azure/msal-browser";
import {useAuth, useSetUser} from "../slices/auth/hooks";
import {authClient} from "../../utilities/auth/client";
import {browser2ServiceWorkerChannel} from "../../workers/serviceworkers/channels/browser2ServiceWorkerChannel";
import {LoadingPage} from "../../pages/LoadingPage";
import {MessageType} from "../../workers/shared/channels/messageTypes";
import {Logger} from "../../utilities/logger";
import {Config} from "../../utilities/config";
import {PromiseUtils} from "../../utilities/promiseUtils";
import {WorkerMessageFactory} from "../../workers/shared/workerMessageFactory";
import {AuthStateUser} from "../slices/auth/types";
import {InteractionRequiredAuthError} from "@azure/msal-common";
import {useBrowserNetworkStatus} from "../slices/status/hooks";
import {NetworkStatus} from "../slices/status/types";
import dayjs from "dayjs";
import { AuthUtils } from "utilities/auth/authUtils";
import {TokenType} from "../../utilities/auth/tokenType";
import {setApmUser} from "../../apm";

const logger = Logger.create("AuthProvider");

const getTokenByType = (account: AccountInfo, type: string) => {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key) {
            const value = localStorage.getItem(key);

            if (value) {
                try {
                    const json = JSON.parse(value);

                    if (json.credentialType === type && json.homeAccountId === account.homeAccountId) {
                        return json;
                    }
                } catch (ignore) {}
            }
        }
    }
};

const getRefreshToken = (accountInfo?: AccountInfo) => {
    const account = accountInfo || authClient.getActiveAccount();
    if (!account) {
        return null;
    }

    return getTokenByType(account, "RefreshToken")?.secret;
};

export interface AuthProviderProps {
    unauthenticatedChildren?: ReactNode;
    children?: ReactNode;
}

const installToken = async (context: {
    account: AccountInfo;
    setUser: (user: AuthStateUser) => void
}, accessToken: string, expiresOn?: Date) => {
    const {account, setUser} = context;

    logger.debug("Got token");
    setUser({
        info: account,
        token: accessToken
    });

    logger.debug("Getting refresh token...");
    const refreshToken = getRefreshToken(account);

    logger.debug("Got refresh token, sending message to service worker...");
    await PromiseUtils.retryWithTimeout(() => browser2ServiceWorkerChannel.sendRpc(WorkerMessageFactory.createUserLoggedInMessage({
        accountId: account.homeAccountId,
        accessToken,
        refreshToken,
        expiresOn: expiresOn?.getTime(),
        userEmail: account.username
    })), 2_000, 10);
};

const InnerAuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [loaded, setLoaded] = useState(false);

    const browserNetworkStatus = useBrowserNetworkStatus();

    const {user} = useAuth();
    const setUser = useSetUser();

    const handleToken = async () => {
        const cachedAccessToken = getAccessToken();
        if (cachedAccessToken) {
            try {
                const skipRefresh = browserNetworkStatus === NetworkStatus.OFFLINE;
                const [accessToken] = await AuthUtils.getTokenAndRefreshIfNeeded(cachedAccessToken, getRefreshToken(), skipRefresh);

                await installToken({
                    account: authClient.getActiveAccount()!,
                    setUser
                }, accessToken.accessToken, new Date(accessToken.expiresOn));
            } catch (error) {
                console.log("Error getting or refreshing token", error);
            }

            setLoaded(true);
        } else {
            const silentResult = await authAcquireTokenSilentOrRedirect();

            if (silentResult) {
                await installToken({
                    account: authClient.getActiveAccount()!,
                    setUser
                }, silentResult.accessToken, silentResult.expiresOn || undefined);
                setLoaded(true);
            } else {
                const browserCacheManager = (authClient["controller"] as any)["browserStorage"] as any;
                if (!browserCacheManager.getInteractionInProgress()) {
                    await authAcquireTokenRedirect();
                }
            }
        }
    };

    useEffect(() => {
        async function run() {
            await authClient.initialize();

            const accounts = authClient.getAllAccounts();
            const accountEnvironment = new URL(Config.OAUTH_AUTHORITY).hostname;
            const account = accounts.find(account => account.environment === accountEnvironment);
            if (account) {
                authClient.setActiveAccount(account);
                setApmUser(account.homeAccountId, account.username);
            }

            authClient.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const payload = event.payload as AuthenticationResult;
                    const account = payload.account;
                    authClient.setActiveAccount(account);
                    setApmUser(account.homeAccountId, account.username);

                    installToken({
                        account: authClient.getActiveAccount()!,
                        setUser
                    }, payload.accessToken, payload.expiresOn || undefined);
                    setLoaded(true);
                }
            });

            handleToken();
        }

        if (!user) {
            run();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, setUser]);

    useEffect(() => {
        const handle = browser2ServiceWorkerChannel.subscribe(MessageType.REFRESH_TOKEN_EXPIRED, async () => {
            logger.debug("Refresh token expired");

            if (browserNetworkStatus !== NetworkStatus.OFFLINE) {
                handleToken();
            }
        });

        return () => {
            browser2ServiceWorkerChannel.unsubscribe(MessageType.REFRESH_TOKEN_EXPIRED, handle);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUser]);

    if (!loaded) {
        return <>{props.unauthenticatedChildren || <LoadingPage/>}</>;
    }

    return (
        <>{props.children}</>
    );
};

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    return (
        <MsalProvider instance={authClient}>
            <InnerAuthProvider unauthenticatedChildren={props.unauthenticatedChildren}>
                {props.children}
            </InnerAuthProvider>
        </MsalProvider>
    );
};

export const useLogout = () => {
    const {instance} = useMsal();
    const account = useAccount(instance.getActiveAccount() || {});

    return useCallback(() => {
        browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createUserLoggedOutMessage());

        instance.logout({
            account,
            extraQueryParameters: {id_token_hint: account?.idToken || ""}
        });
    }, [instance, account]);
};

const acquireTokenRequestDefault = {
    scopes: Config.OAUTH_CLIENT_SCOPES
};

export const authAcquireTokenSilent = async () => {
    const account = authClient.getActiveAccount();

    if (account) {
        const response = await authClient.acquireTokenSilent({
            ...acquireTokenRequestDefault,
            account: account
        });

        return response;
    }

    return null;
};

export const authAcquireTokenRedirect = async (accountInfo?: AccountInfo) => {
    const account = accountInfo || authClient.getActiveAccount();

    await authClient.acquireTokenRedirect({
        ...acquireTokenRequestDefault,
        account: account || undefined
    });
};

export const authAcquireTokenSilentOrRedirect = async () => {
    try {
        const result = await authAcquireTokenSilent();

        return result;
    } catch (error: any) {
        logger.error("Error acquiring token silently", error);
        if (error instanceof InteractionRequiredAuthError/*isInteractionRequiredError(error.errorCode)*/) {
            await authAcquireTokenRedirect();
        } else if (error.message?.startsWith("no_tokens_found")) {
            await authAcquireTokenRedirect();
        }
    }
};

export const getAccessToken = (accountInfo?: AccountInfo): {account: AccountInfo} & TokenType | null => {
    const account = accountInfo || authClient.getActiveAccount();
    if (!account) {
        console.log("No account set");
        return null;
    }

    const accessToken = getTokenByType(account, "AccessToken");
    const expiresOn = accessToken["expiresOn"] ? dayjs(Number.parseInt(accessToken["expiresOn"]) * 1000) : null;
    if (!expiresOn || expiresOn.isBefore(dayjs())) {
        console.log("Token expired", expiresOn);
        return null;
    }

    return {accessToken: accessToken.secret, refreshToken: "", account, expiresOn: expiresOn.toDate().getTime()};
};

browser2ServiceWorkerChannel.subscribe(MessageType.TOKEN_REFRESHED, (message) => {
    logger.info("Received TOKEN_REFRESHED");

    const {
        id,
        accessToken,
        refreshToken,
        idToken,
        expiresOn
    } = message.payload;

    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        if (key?.startsWith(id)) {
            const value = localStorage.getItem(key);

            if (value) {
                try {
                    const json = JSON.parse(value);

                    if (["RefreshToken", "IdToken", "AccessToken"].includes(json.credentialType)) {
                        if (json.credentialType === "RefreshToken") {
                            json.secret = refreshToken;
                        } else if (json.credentialType === "IdToken") {
                            json.secret = idToken;
                        } else if (json.credentialType === "AccessToken") {
                            json.secret = accessToken;
                            json.expiresOn = String(expiresOn / 1000);
                            json.extendedExpiresOn = String(expiresOn / 1000);
                        }

                        logger.debug("Updated item", key);
                        localStorage.setItem(key, JSON.stringify(json));
                    }
                } catch (error) {

                }
            }
        }
    }
});
