import * as Sentry from "@sentry/react";
import {Config} from "./utilities/config";

Sentry.init({
    dsn: Config.SENTRY_DSN,
    release: Config.VERSION,

    environment: Config.ENVIRONMENT,
    enabled: !!Config.SENTRY_DSN,

    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    tracesSampleRate: Config.SENTRY_TRACES_SAMPLE_RATE,

    replaysOnErrorSampleRate: 1
});

export const setApmUser = (id: string, email: string) => {
    Sentry.setUser({id, email});
};
