import {Message, SimpleRpcChannel} from "./channels/simpleRpcChannel";
import {RpcProvider} from "worker-rpc";
import {BrowserUtils} from "../../utilities/browserUtils";
import {WebWorkerMessageType} from "../webworker/webWorkerMessageType";
import {WebWorkerName} from "../webworker/webWorkerName";
import {MessageType} from "./channels/messageTypes";
import {Logger} from "../../utilities/logger";
import {browser2ServiceWorkerChannel} from "../serviceworkers/channels/browser2ServiceWorkerChannel";
import {WorkerMessageFactory} from "./workerMessageFactory";

/**
 * Keeps track of all web workers and manages the communication channels between them.
 */
export class WorkerRegistry {

    private logger: Logger = Logger.create("WorkerRegistry");

    private registry: Record<string, Worker> = {};
    private rpcChannels: Record<string, SimpleRpcChannel> = {};

    constructor() {
        if (!BrowserUtils.isBrowser()) {
            throw new Error("WorkerRegistry does not work within workers");
        }

        // TODO: beter oplossen
        browser2ServiceWorkerChannel.subscribe(WebWorkerMessageType.SEND_MESSAGE_TO_WEBWORKERS, (message) => {
            this.logger.debug("Sending message to web workers", message, Object.keys(this.rpcChannels));
            for (const channel of Object.values(this.rpcChannels)) {
                channel.publish(Message.fromJSON(message.payload.message));
            }
        });
    }

    public async register(id: string, worker: Worker, extraContext?: any): Promise<MessagePort> {
        this.logger.info(`Registering worker ${id}...`);

        this.registry[id] = worker;

        const channel = new MessageChannel();
        const browserPort = channel.port1;
        const workerPort = channel.port2;

        const rpcChannel = this.createRpcChannel(browserPort);
        rpcChannel.registerRpcHandler(WebWorkerMessageType.CREATE_CONNECTION, (message) => {
            this.onRequestConnectionToWorker(id, message);
        });
        rpcChannel.subscribe(WebWorkerMessageType.SEND_MESSAGE_TO_SERVICEWORKER, (message) => {
            this.logger.debug("Sending message from web worker to service worker", message);
            browser2ServiceWorkerChannel.publish(WorkerMessageFactory.createSendMessageToServiceWorkerMessage(message.payload.message));
        });

        this.rpcChannels[id] = rpcChannel;

        this.logger.info(`Initializing worker ${id}...`);
        await new Promise<void>((resolve) => {
            worker.onmessage = (message) => {
                  if (message.data.type === WebWorkerMessageType.INITIALIZED) {
                      resolve();
                  }
            };

            worker.postMessage({type: WebWorkerMessageType.INITIALIZE, scope: window._env_, extraContext, port: workerPort}, [workerPort]);
        });

        this.logger.info(`Initialized worker ${id}`);

        return browserPort;
    }

    public async start() {
        for (const channel of Object.values(this.rpcChannels)) {
            await channel.sendRpc(new Message<any, string>({type: WebWorkerMessageType.START}));
        }
    }

    public getBrowserRpcChannelForWorker(id: string): SimpleRpcChannel {
        if (!this.rpcChannels) {
            throw new Error("No rpc channel for worker " + id);
        }

        return this.rpcChannels[id];
    }

    public async createMessageChannelBetweenWorkers(worker1Id: string, worker2Id: string) {
        const channel = new MessageChannel();

        const worker1Channel = this.getBrowserRpcChannelForWorker(worker1Id);
        const worker2Channel = this.getBrowserRpcChannelForWorker(worker2Id);

        this.logger.info(`Registering connection in ${worker1Id} for ${worker2Id}...`);
        await worker1Channel.sendRpc(new Message<any, string>({
            type: WebWorkerMessageType.REGISTER_CONNECTION,
            payload: {
                workerId: worker2Id,
                port: channel.port1
            }
        }), [channel.port1]);

        this.logger.info(`Registering connection in ${worker2Id} for ${worker1Id}...`);
        await worker2Channel.sendRpc(new Message<any, string>({
            type: WebWorkerMessageType.REGISTER_CONNECTION,
            payload: {
                workerId: worker1Id,
                port: channel.port2
            }
        }), [channel.port2]);
    }

    public subscribeReduxStore(handler: (message: Message) => any) {
        [
            WebWorkerName.SYNC_ARTIKEL_AFBEELDINGEN_WEB_WORKER,
            WebWorkerName.SYNC_FROM_SERVER_WEB_WORKER
        ].forEach(id => this.getBrowserRpcChannelForWorker(id).subscribe(MessageType.REDUX, handler));
    }

    private async onRequestConnectionToWorker(originWorkerId: string, message: Message) {
        const {workerId} = message.payload;
        this.logger.info(`Got request for connection to worker ${workerId} from ${originWorkerId}`);

        await this.createMessageChannelBetweenWorkers(originWorkerId, workerId);

        this.logger.info(`${originWorkerId} is now connected to ${workerId}`);
    }

    private createRpcChannel(port: MessagePort): SimpleRpcChannel {
        const rpcProvider: RpcProvider = new RpcProvider((message, transfer) => {
            port.postMessage(message, transfer as Transferable[]);
        });

        port.onmessage = (message) => {
            rpcProvider.dispatch(message.data);
        };

        return new SimpleRpcChannel<any, string>(rpcProvider);
    }
}

export const workerRegistry = new WorkerRegistry();
