import {Message} from "./channels/simpleRpcChannel";
import {MessageType} from "./channels/messageTypes";
import {
    BezoekSessie,
    BezoekSessieEventMessage,
    BezoekSessieEventPayload
} from "../../_generated/field-service-be-openapi";
import {EventMessagePayload} from "./channels/eventMessage";
import {AnyAction} from "@reduxjs/toolkit";
import {ErrorUtils} from "../../utilities/errorUtils";
import {WebWorkerMessageType} from "../webworker/webWorkerMessageType";
import {BezoekSessieSnapshotFactoryResult} from "./snapshot/bezoekSessieSnapshotFactory";
import {TokenType} from "../../utilities/auth/tokenType";

// TODO: per message een klasse aan die message extend waardoor we rechtstreeks de constructor met de juiste params kunnen aanroepen?
export class WorkerMessageFactory {

    public static createFrontendErrorMessage(error: Error) {
        return new Message({
            payload: {
                error: {
                    name: error.name,
                    message: error.message,
                    stack: error.stack
                }
            },
            type: MessageType.FRONTEND_ERROR
        });
    }

    public static createRemoveErrorMessage(id: string) {
        return new Message({
            payload: {
                id
            },
            type: MessageType.REMOVE_ERROR
        });
    }

    public static createClearErrorsMessage() {
        return new Message({
            payload: {},
            type: MessageType.CLEAR_ERRORS
        });
    }

    public static createQrMessage(qrMessage: { width?: number | ConstrainULongRange; height?: number | ConstrainULongRange; buffer: Buffer }) {
        return new Message({
            payload: qrMessage,
            type: MessageType.QR
        });
    }

    public static createUserLoggedInMessage(args: { accountId: string; accessToken: string; refreshToken: string; expiresOn?: number; userEmail: string }) {
        return new Message({
            type: MessageType.USER_LOGGED_IN,
            payload: {
                accountId: args.accountId,
                accessToken: args.accessToken,
                refreshToken: args.refreshToken,
                expiresOn: args.expiresOn,
                userEmail: args.userEmail
            }
        });
    }

    public static createUserLoggedOutMessage() {
        return new Message({
            type: MessageType.USER_LOGGED_OUT
        });
    }

    public static createBrowserBezoekSessieEventMessage(args: { bezoekSessieId: string, technicusId: string, event: BezoekSessieEventPayload }) {
        return new Message({
            payload: {
                bezoekSessieId: args.bezoekSessieId,
                technicusId: args.technicusId,
                event: args.event
            } as EventMessagePayload,
            type: MessageType.BROWSER_BEZOEKSESSIE_EVENT
        });
    }

    public static createGetAccessTokenMessage() {
        return new Message({type: MessageType.GET_ACCESS_TOKEN});
    }

    public static createSwOnlineMessage() {
        return new Message({type: MessageType.SW_ONLINE});
    }

    public static createSwOfflineMessage() {
        return new Message({type: MessageType.SW_OFFLINE});
    }

    public static createSyncPlanningMessage(datum: string, technicusId?: string) {
        return new Message({
            type: MessageType.SYNC_PLANNING,
            payload: {datum, technicusId}
        });
    }

    public static createDagPlanningDatumGewijzigdDoorGebruikerMessage(datum: string) {
        return new Message({
            type: MessageType.DAG_PLANNING_DATUM_GEWIJZIGD_DOOR_GEBRUIKER,
            payload: {datum}
        });
    }

    public static createReduxMessage(payload: AnyAction) {
        return new Message({
            type: MessageType.REDUX,
            payload
        });
    }

    public static createArtikelAfbeeldingenSyncCompleteMessage() {
        return new Message({
            type: MessageType.ARTIKEL_AFBEELDINGEN_SYNC_COMPLETE
        });
    }

    public static createSyncToServerCompleteMessage(bezoekSessieIds: string[]) {
        return new Message({
            type: MessageType.SYNC_TO_SERVER_COMPLETE,
            payload: {
                bezoekSessieIds
            }
        });
    }

    public static createSwErrorMessage(error: Error) {
        return new Message({
            payload: {
                error: ErrorUtils.toObject(error)
            },
            type: MessageType.SW_ERROR
        });
    }

    public static createSendMessageToServiceWorkerMessage(message: any) {
        return new Message({
            type: WebWorkerMessageType.SEND_MESSAGE_TO_SERVICEWORKER,
            payload: {
                message
            }
        });
    }

    public static createSendMessageToWebWorkerMessage(message: any) {
        return new Message({
            type: WebWorkerMessageType.SEND_MESSAGE_TO_WEBWORKERS,
            payload: {
                message
            }
        });
    }

    public static createLocalEventCreatedMessage(message: BezoekSessieEventMessage) {
        return new Message({type: MessageType.LOCAL_EVENT_CREATED, payload: message});
    }

    public static createServerEventCreatedMessage(message: BezoekSessieEventMessage) {
        return new Message({type: MessageType.SERVER_EVENT_CREATED, payload: message});
    }

    public static createChannelConnectionMessage(workerId: string) {
        return new Message({
            type: WebWorkerMessageType.CREATE_CONNECTION,
            payload: {
                workerId
            }
        });
    }

    public static createGetWebSocketStatusMessage() {
        return new Message({type: MessageType.GET_WEB_SOCKET_STATUS});
    }

    public static createLocalFileCreatedMessage() {
        return new Message({type: MessageType.LOCAL_FILE_CREATED});
    }

    public static createManualSyncToServerMessage() {
        return new Message({type: MessageType.MANUAL_SYNC_TO_SERVER});
    }

    public static createHydrateFrontendMessage() {
        return new Message({type: MessageType.HYDRATE_FRONTEND});
    }

    public static createCalculateBezoekSessieSnapshotMessage() {
        return new Message({type: MessageType.CALCULATE_BEZOEKSESSIE_SNAPSHOT});
    }

    public static createCalculateBezoekSessieSnapshotByIdMessage(bezoekSessieId: string) {
        return new Message({type: MessageType.CALCULATE_BEZOEKSESSIE_SNAPSHOT_BY_ID, payload: {bezoekSessieId}});
    }

    public static createPeriodSyncRunningMessage() {
        return new Message({type: MessageType.PERIODIC_SYNC_RUNNING});
    }

    public static createTokenRefreshedMessage(args: {id: string} & TokenType) {
        return new Message({
            type: MessageType.TOKEN_REFRESHED,
            payload: {
                id: args.id,
                accessToken: args.accessToken,
                refreshToken: args.refreshToken,
                idToken: args.idToken,
                expiresOn: args.expiresOn
            }
        });
    }

    public static createRefreshTokenExpiredMessage() {
        return new Message({type: MessageType.REFRESH_TOKEN_EXPIRED});
    }

    public static createHydrateFrontendCompleteMessage() {
        return new Message({
            type: MessageType.HYDRATE_FRONTEND_COMPLETE
        });
    }

    public static createSyncBezoekSessieByIdMessage(bezoekSessieId: string) {
        return new Message({type: MessageType.SYNC_ACTIEVE_BEZOEKSESSIES, payload: {bezoekSessieId}});
    }

    public static createSyncActieveBezoekSessiesMessage(datum?: string) {
        return new Message({type: MessageType.SYNC_ACTIEVE_BEZOEKSESSIES, payload: {datum}});
    }

    public static createSyncArtikelenMessage(force: boolean) {
        return new Message({type: MessageType.SYNC_ARTIKELEN, payload: {force}});
    }

    public static createSyncArtikelenCompleteMessage() {
        return new Message({type: MessageType.ARTIKELEN_SYNC_COMPLETE});
    }

    public static createSyncArtikelAfbeeldingenMessage() {
        return new Message({type: MessageType.SYNC_ARTIKEL_AFBEELDINGEN});
    }

    public static createUploadFileMessage(blob: Blob) {
        return new Message({
            payload: {
                blob
            },
            type: MessageType.UPLOAD_FILE
        });
    }

    public static createActieveBezoekSessiesSyncCompleteMessage(bezoekSessies: BezoekSessie[]) {
        return new Message({
            type: MessageType.SERVER_ACTIEVE_BEZOEKSESSIES_SYNC_COMPLETE,
            payload: {bezoekSessies}
        });
    }

    public static createBezoekSessieEventsSyncCompleteMessage(datum: string) {
        return new Message({
            type: MessageType.SERVER_BEZOEKSESSIE_EVENTS_SYNC_COMPLETE,
            payload: {datum}
        });
    }

    public static createPlanningAangepastMessage(args: {
        dagPlanningId: string;
        datum: string;
        tijdstip: string;
    }) {
        return new Message({
            type: MessageType.PLANNING_AANGEPAST,
            payload: {
                dagPlanningId: args.dagPlanningId,
                datum: args.datum,
                tijdstip: args.tijdstip
            }
        });
    }

    public static createBezoekSessieSnapshotsMessage(snapshots: BezoekSessieSnapshotFactoryResult[]) {
        return new Message({
            type: MessageType.BEZOEKSESSIE_SNAPSHOT,
            payload: {
                snapshots
            }
        });
    }

}
