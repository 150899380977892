import * as React from "react";
import {Config} from "utilities/config";
import {ToestelInterventieTaakTypeEnum} from "../../../_generated/field-service-be-openapi";
import {MDBBadge} from "mdb-react-ui-kit";
import { backgroundColor } from "mdb-react-ui-kit/types/colors";

export interface InterventieTaakTypeBadgeProps {
    type?: ToestelInterventieTaakTypeEnum;
}

export const InterventieTaakTypeBadge: React.FC<InterventieTaakTypeBadgeProps> = ({type}) => {
    if (!type) {
        return null;
    }

    const config: Record<string, {label: React.ReactNode; color: backgroundColor}> = {
        [ToestelInterventieTaakTypeEnum.Herstelling]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Herstelling],
            color: "danger"
        },
        [ToestelInterventieTaakTypeEnum.Upgrade]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Upgrade],
            color: "primary"
        },
        [ToestelInterventieTaakTypeEnum.Verplaatsing]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Verplaatsing],
            color: "secondary"
        },
        [ToestelInterventieTaakTypeEnum.Verhuis]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Verhuis],
            color: "warning"
        },
        [ToestelInterventieTaakTypeEnum.Ophaling]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Ophaling],
            color: "success"
        },
        [ToestelInterventieTaakTypeEnum.StaalnameLaboOnderzoek]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.StaalnameLaboOnderzoek],
            color: "info"
        },
        [ToestelInterventieTaakTypeEnum.Advies]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Advies],
            color: "info"
        },
        [ToestelInterventieTaakTypeEnum.Opstart]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.Opstart],
            color: "info"
        },
        [ToestelInterventieTaakTypeEnum.RolloutRecallActie]: {
            label: Config.labels[ToestelInterventieTaakTypeEnum.RolloutRecallActie],
            color: "info"
        }
    };

    const configForType = config[type];

    return (
        <MDBBadge color={configForType.color}>{configForType.label}</MDBBadge>
    );
};
